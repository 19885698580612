import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
         <div className="announcement">
        <b>
      <h1>OUR NEW WEBSITE IS LAUNCHING SOON</h1>
      <h2>CONTACT US FOR FURTHER INFORMATION AND TO BOOKS ORDER</h2>
      <h2>NARMADHA PATHIPAGAM</h2>
      <h2><a href="mailto:narmadhapathipagam@gmail.com">narmadhapathipagam@gmail.com</a></h2>
      <h2>98402 26661 / 98409 32566 / 99400 45044</h2>
      </b>
      </div>
    </div>
  );
}

export default App;
